import React, { useEffect, useState, useContext } from "react";
// import '../assets/oxy.css'
import "../assets/ocean.css";
import Axios from "axios";
import spinner from "../assets/oxy_assets/829.gif";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { options } from "../components/Options";
import Select from "react-select";
import "react-phone-number-input/style.css";
import PhoneInput, {
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import logo from "../assets/oxy_assets/logo.png";
import logo_transp from "../assets/oxy_assets/logo_transp/logo_transp.png";
import oceanWb from "../assets/oxy_assets/ocean.svg";
import oceanGroupLogo from "../assets/oxy_assets/group-logo.svg";
import oceanGroupLogoDB from "../assets/oxy_assets/oceanGroupLogoDB.svg";

import oceanBb from "../assets/oxy_assets/ocean_white.svg";
import bgImage from "../assets/oxy_assets/TGBC_light_BG.svg";
import bgImage_db from "../assets/oxy_assets/bgImage_db.svg";
import { API_URL } from "../constants";


import * as XLSX from 'xlsx';
// import $ from 'jquery'
// import {Link} from 'react-router-dom'

// import  ocean_white from "../assets/oxy_assets/ocean.svg"

import $, { cssHooks, trim } from "jquery";
// import { copy } from '../../../../serp_api/node_scrap/api';
const Scrap = () => {
  const [inputValue, setInputValue] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    phone: "",
    csv_name: "",
    chooseItems: ['database','google'],

    // 'multiple_query':''
    // 'url':''
  });
  const [responseData, setResponseData] = useState({});
  const [formerror, setFormError] = useState({});

  const [list, setList] = useState([]);

  const [isSubmit, setIsSubmit] = useState(false);
  const [selectedOption, setSelectedOption] = useState([...options]);
  const [modeState, setModeState] = useState("light");
  const [infoArr, setInfoArr] = useState(["https://www.casecheck.co.uk"]);
  const [checkboxStatus,setCheckboxStatus]=useState(null)
  useEffect(() => {

    // selectedOption.find(obj=>obj.label=="google-search"
    let tempChooseItem=inputValue.chooseItems;
    let cpySelectedOption=[...selectedOption];
    let mainCpySelectedOption=[...cpySelectedOption.filter((e)=>e.label!=="google-search")]
    console.log(mainCpySelectedOption,"mainCpyselected option")
    // setSelectedOption([...mainCpySelectedOption])
    // setSelectedOption(()=>{let cpySelectedOption=[...selectedOption];
    //   return cpySelectedOption.filter((e)=>e.label!=="google-search")
    //  })
    if(tempChooseItem.length>0)
    {
console.log("tempmmmm")
if(tempChooseItem.length==2)
{
  console.log("in both state")
setCheckboxStatus("both-state");
  console.log("in both state usestae",selectedOption);
  setSelectedOption(()=>{return([...mainCpySelectedOption,{label:"google-search",value:"google-search-val"}])});
  // console.log("in both state")

// if(value=="google")
// {


// }
}
else if(tempChooseItem.find(
  (arr) => arr == "google"))
  {
    console.log("in google")
    setCheckboxStatus("google-state");
  setSelectedOption(()=>{return([...mainCpySelectedOption,{label:"google-search",value:"google-search-val"}])});





  }
  else if(tempChooseItem.find(
    (arr) => arr == "database"))
    {
    console.log("in database")

      setCheckboxStatus("database-state");
      setSelectedOption(mainCpySelectedOption)
      // setSelectedOption(()=>{let cpySelectedOption=[...selectedOption];
      //   return cpySelectedOption.filter((e)=>e.label!=="google-search")
      //  })
  
  
    }
    // else{

    //         setSelectedOption(()=>{let cpySelectedOption=[...selectedOption];
    //        return cpySelectedOption.filter((e)=>e.label!=="google-search")
    //       })

    // }

    }

    else {
      console.log("nillll")
      setSelectedOption(mainCpySelectedOption)

setCheckboxStatus(null);

      
    }
    // else{
    //             setSelectedOption(()=>{let cpySelectedOption=[...selectedOption];
    //        return cpySelectedOption.filter((e)=>e.label!=="google-search")
    //       })
    // }





   // if(value=="google")
        // {
        
        //   setSelectedOption(()=>{let cpySelectedOption=[...selectedOption];
        //    return cpySelectedOption.filter((e)=>e.label!=="google-search")
        //   })
        
        // }

    // setModeState("dark")
  }, [inputValue.chooseItems]);

  useEffect(()=>{

  },[modeState])

  // useEffect(() => {
  //   // setSelectedOption(()=>{
  //   // return(options.filter(item=>item.value!='All'))

  //   // })
  //   setSelectedOption(options);
  // }, []);

  let removeDuplicateItem = (arr) => {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  };
  useEffect(() => {
    let arr = [];
    console.log("loklll pppp")
    // if(checkboxStatus!=="google-state"){
    if ((selectedOption.length > 0) && (checkboxStatus!=="google-state")&&(checkboxStatus)) {
      // console.log("inn")
      selectedOption.forEach((option) => {
        // switch (url.label) {
        //   case "https://www.casecheck.co.uk":
        switch (option.label) {
          case "https://www.familywatchdog.us/ShowNameList.asp":
            // arr.push("First Name")
            arr.push("Family/Father Name");
            break;

          case "https://www.gov.uk/government/publications/financial-sanctions-consolidated-list-of-targets/consolidated-list-of-targets":
            arr.push("Family/Father Name");
            break;
          case "https://www.interpol.int/en/How-we-work/Notices/View-Red-Notices":
            arr.push("Family/Father Name");
            break;
          case "https://www.insolvencydirect.bis.gov.uk/eiir/IIRRegisterNameInput.asp?option=NAME&court=ALL":
            arr.push("Family/Father Name");
            break;
          case "https://isi.jahs.ie/public/cases":
            arr.push("First Name");
            arr.push("Family/Father Name");
            break;
          case "http://ir.drcor.mcit.gov.cy/home/searchperson":
            arr.push("First Name");
            arr.push("Family/Father Name");
            break;
          case "https://www.search.gov.hk/result":
            // arr.push("First Name")
            arr.push("Family/Father Name");
            break;
          case "https://rupep.org/en/search?q=":
            // arr.push("First Name")
            arr.push("Family/Father Name");
            break;
          case "https://судебныерешения.рф/extended-search":
            arr.push("First Name");
            break;
          case "https://www.bop.gov/inmateloc/":
            arr.push("First Name");
            arr.push("Family/Father Name");
            break;
          case "https://rps.ms.gov.pl/en-US/Public#/home":
            arr.push("Family/Father Name");
            break;
            case "https://poszukiwani.policja.pl/pos/form/5,dok.html":
              arr.push("Family/Father Name");
              break;
              case "https://wanted.mvs.gov.ua/searchperson":
                arr.push("First Name");  
              arr.push("Family/Father Name");
                break;

          // default:
          //   arr.push("CSV Name")
        }
      });
      // console.log("inn",arr)
      // let removeDuplicateItem = (arr) => {
      //   return arr.filter((item, index) => arr.indexOf(item) === index);
      // };
      arr.push("CSV Name");
      setList(() => {
        return removeDuplicateItem(arr);
      });
    } else {
      arr = [];
      setList(arr);
    }


    if(checkboxStatus=="google-state")
    {
      arr.push("CSV Name");
      setList(() => {
        return removeDuplicateItem(arr);
      });
    }


    // let removeDuplicateItem = (arr) => {
    //   return arr.filter((item, index) => arr.indexOf(item) === index);
    // };
    // arr.push("CSV Name");
    // setList(() => {
    //   return removeDuplicateItem(arr);
    // });


  }, [selectedOption]);

  const handleSelect = (val) => {
    console.log(val)

    setSelectedOption(val);

console.log("innn")

    // if (selectedOption && selectedOption.label === 'google-search') {
    //   console.log("inn google")
    //   // setFilteredOptions(selectedOption.filter(opt => opt.label === 'google-search'));
    // } 
    
    // else {
    //   // setFilteredOptions(allOptions);
    // setSelectedOption(val);

    // }

    // if(val.length>=1 && val[0].value=='All')
    // {
    //     setSelectedOption(()=>{
    //         return(options.filter(item=>item.value!='All'))

    //         })
    // }

    // else{
    //     setSelectedOption(val)
    // }
  };
  const changeInput = (e) => {
    const { name, value } = e.target;

    setInputValue({
      ...inputValue,
      //for random id in local storage
      //  id:shuffle(numbers),
      //for id in local storage.....................
      // id:add1(),

      [name]: value,
    });
  };
  const changePhone = (number) => {
    // console.log(number,"number");
    setInputValue({
      ...inputValue,
      phone: number,
    });
    // console.log(inputValue,"")
  };
  const key_count = (data_all, key_to_count) => {
    // let len=0
    let filteredData = data_all.filter(
      (singular_data_array, index) =>
        singular_data_array[singular_data_array.length - 1].key == key_to_count
    );
    // filteredData.forEach((eachData)=>{
    //   len=eachData.length+len
    // })
    return filteredData.length;
    // if(key_to_count)
  };
  let position_detector = (key) => {
    let pos = "";
    switch (key) {
      case "url":
        pos = 6;
        break;
      case "title":
        pos = 7;
        break;
      case "description":
        pos = 8;
        break;
      case "date":
        pos = 9;
        break;
      case "scam_type":
        pos = 10;
        break;
      case "scammer_email":
        pos = 12;
        break;
      case "scammer_username":
        pos = 11;
        break;
      case "scam_description":
        pos = 8;
        break;
      case "image":
        pos = 14;
        break;
      case "profile_url":
        pos = 6;
        break;
      case "name":
        pos = 7;
        break;
      // scammer_phone
      case "scammer_phone":
        pos = 13;
        break;
      case "scam_website":
        pos = 6;
        break;
      case "google_url":
        pos = 0;
        break;
      case "google_search_term":
        pos = -1;
        break;

      default:
        pos = -1;
      // scam_website
    }
    return pos;
  };
  let filteredInput = () => {
    let filteredString = "";
    // console.log(inputValue,"inputvalue")
    Object.keys(inputValue).forEach((key, index) => {
      // console.log(inputValue[key],key,"keyyy")

      if (inputValue[key] && key != "csv_name" && key != "chooseItems" ) {
        filteredString = filteredString + "," + inputValue[key];
      }
    });

    return filteredString.slice(1, filteredString.length);
  };
  const google_csv_file = (google_data) => {
    // let whole_data=whol_data[1]
    let main_array = [];
    // whole_data.map((each_scrap_data,index)=>{

    google_data.map((url_object, url_object_index) => {
      Object.keys(url_object).map((web_url, web_url_index) => {
        // let data_all=url_object[web_url].slice(0,(url_object[web_url].length-1))
        let data_all = url_object[web_url];

        //     if(data_all.length<=0){
        //       console.log(data_all.length,"lengthhh")
        // let another_row_array=[]
        // another_row_array.push(web_url)
        // another_row_array.push(`"${data_all.length}"`)
        // main_array.push(another_row_array)
        // console.log(main_array)

        //     }
        // else{
        data_all.map((d_t, d_t_index) => {
          let data = d_t.slice(0, d_t.length - 1);
          // })
          // console.log(data,"data")
          //   })
          // })
          // console.log(data,"dataaaaa");
          data.map((item, index) => {
            let row_array = [];
            // if(index==0 && d_t_index==0){

            //   row_array.push(`"${web_url}"`)
            //   row_array.push(`"${data_all.length}"`)
            //   row_array.push(`"${key_count(data_all,'name')}"`)
            //   row_array.push(`"${key_count(data_all,'email')}"`)
            //   row_array.push(`"${key_count(data_all,'phone')}"`)
            // }

            // row_array[5]=d_t[d_t.length-1].key
            // console.log(item,"itemmm")
            Object.keys(item).map((key, i) => {
              // console.log(key,"keyyy")
              if ( key == "google_url") {
                let pos = +position_detector(key);
                // website,totalCount,nameCount,emailCount,phoneCount,searchKey,url,title/name,description,date,scam_type,scammerUsername,scammerEmail,image

                let dumy_value = item[key];
                // console.log(dumy_value,"dumyyvalue")
                // if (item[key].split('"').length > 1) {
                //   let add_string = "";
                //   // let split_array=item[key].split('"');
                //   const searchStr = '"';
                //   const indexes = [
                //     ...dumy_value.matchAll(new RegExp(searchStr, "gi")),
                //   ].map((a) => a.index);

                //   indexes.forEach((k, l) => {
                //     let txt1 = dumy_value;
                //     let i = k + l;
                //     // dumy_value.splice(k, 0, '"');
                //     // if(l>=1){
                //     // i=k+l
                //     // }
                //     dumy_value =
                //       dumy_value.slice(0, i) + '"' + dumy_value.slice(i);
                //     // i=i+1
                //   });

                //   //         split_array.map((sp_elem)=>{
                //   //   if(sp_elem.search(/,/gm)>=0){
                //   // add_string=`${add_string}${sp_elem}`
                //   //   }
                //   //   else{
                //   //     add_string=`"${add_string}${sp_elem}"`
                //   //   }
                //   //         })
                //   // add_string=add_string.slice(1,(add_string.length))
                //   // console.log(add_string,"add_stringg")
                //   // console.log(dumy_value,"dumy value");

                //   // row_array.push(`"${dumy_value}"`)
                
                //     // row_array[pos] = `=HYPERLINK("${dumy_value}")`;
                //     // row_array[1] = `=HYPERLINK("${item}")`
                //     row_array[pos] = `"${dumy_value}"`;

                  
             
                // }
                // else {
              
                  
                //   // row_array.push(`"${item[key]}"`)
                  
                // }
                row_array[pos] = dumy_value;
              }
            });
            // row_array.push(d_t[d_t.length-1].key)
            // if(index==0){
            // row_array.push(url_object[web_url][url_object[web_url].length-1].count_number)
            // }
            // else{
            //   row_array.push('')
            // }
            // console.log(row_array,"rowarray")
            main_array.push(row_array);
          });
        });
        // }
      });
    });
    // whole_data[1].map((url_object,url_object_index)=>{
    //   Object.keys(url_object).map((web_url,web_url_index)=>{

    //   })})
    //  })
    return main_array;

    // var csvFileData = [
    //     ['Alan Walker', 'Singer'],
    //     ['Cristiano Ronaldo', 'Footballer'],
    //     ['Saina Nehwal', 'Badminton Player'],
    //     ['Arijit Singh', 'Singer'],
    //     ['Terence Lewis', 'Dancer']
    //  ];

    //create a user-defined function to download CSV file
  };
  const website_csv_file = (data) => {
    console.log(data,"dataaa")
    let main_array = [];
    // whole_data.forEach((data, index) => {
    data.forEach((eachDataObj, item_index) => {
      let item=eachDataObj.query_url_for_excel
      console.log(item,"itemm")
      let row_array = [];
      // if (index == 0 && item_index == 0) {

      // if (item_index == 0) {
      //   let input_csv_string = filteredInput();
      //   // inputs.forEach(())
      //   row_array[0] = `"${input_csv_string}"`;
      // }

      //  row_array[1]=`${item}`
      // console.log(item,"itemm11")
      // row_array[1] = `=HYPERLINK("${encodeURIComponent(item)}","loll")`
      const regexWithHash = /#/gi;
      const regxWithComma = /,/gi;
      if (regexWithHash.test(item)) {
        // item=item.replace(regex, '%23')
        item = item.split("#")[0];
      } else if (regxWithComma.test(item)) {
        item = item.split(",")[0];
      }

      // l.replace(regex, '%23')
      // row_array[1] = `=HYPERLINK("${item}")`;
      // row_array[0] = `=HYPERLINK("${item}")`;
      row_array[0] = item;


      // row_array[0] = `"${item}"`

      // console.log(item,"itemm11")

      //  console.log(row_array,"rowarray")

      // row_array.push(`${item}`)
      main_array.push(row_array);
    });
    // })

    return main_array;
  };
  const download_csv_file = (whole_data) => {
    let main_array = [];
    let website_api_data = whole_data[0];
    let google_api_data = whole_data[1];
    let website_data = [];
    let google_data = [];
    if (website_api_data.length > 0) {
      website_data = website_csv_file(website_api_data);
    }
    if (google_api_data.length > 0) {
      google_data = google_csv_file(google_api_data);
    }
    if (whole_data.length == 3) {
      // console.log(whole_data,"whole data")
      if (whole_data[2].length > 0) {
        $(".excludedDBalert").css("display", "block");
        setInfoArr(whole_data[2]);
      }
    }
    main_array = [...website_data, ...google_data];
    // console.log(main_array,"mainarray")
    // let all_array=document.querySelectorAll(".container .influencers")
    // if(all_array.length>=20){
    //   document.querySelector(a.loadMoreBtn).style.display="none"
    // }
    // else{
    //   document.querySelector(a.loadMoreBtn).style.display="block"
    // }

    // var csvFileData = [
    //     ['Alan Walker', 'Singer'],
    //     ['Cristiano Ronaldo', 'Footballer'],
    //     ['Saina Nehwal', 'Badminton Player'],
    //     ['Arijit Singh', 'Singer'],
    //     ['Terence Lewis', 'Dancer']
    //  ];

    //create a user-defined function to download CSV file

    function csv_file() {
console.log(main_array,"mainarray12345 ---")


// const MYdata = [
//   {"title":"Title1", "website":"Foo"},
//   {"title":"Title2", "website":"Bar"}
// ]

// const worksheet = XLSX.utils.json_to_sheet(MYdata);
// const workbook = XLSX.utils.book_new();
// XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
// const MYSavedData=XLSX.writeFile(workbook, "MYSavedData.xlsx");
// const excelData = [
//   ["https://www.casecheck.co.uk"]
// ];
let excelData=[["website"],...main_array.slice(0)]



console.log(excelData)

      const ws = XLSX.utils.aoa_to_sheet(excelData);


      // const ws = XLSX.utils.aoa_to_sheet(excelData);
      // ws["A1"].l = {
      //     Target: "https://sheetjs.com",
      //     Tooltip: "Find us @ SheetJS.com!"
      //   };
      //   ws["A2"].l = {
      //     Target: "https://sheetjs.com",
      //     Tooltip: "Find us @ SheetJS.com!"
      //   };

      for (let i = 1; i < excelData.length; i++) {
          for (let j = 0; j < excelData[i].length; j++){
          console.log("ihhh")

          ws[`A${i+1}`].l = {
              Target: excelData[i][j],
              // Tooltip: "Find us @ SheetJS.com!"
            };
          }
          // ws[XLSX.utils.encode_cell({
          //   c: 0,
          //   r: i
          // })].l = { Target:"https://www.casecheck1.co.uk"  };
      }

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb,  inputValue.csv_name + ".xlsx");
//       const blob = XLSX.write(wb, { bookType: 'xlsx', type: 'blob' });



//       const wb = XLSX.utils.book_new();
// const ws = XLSX.utils.aoa_to_sheet(excelData);
// XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
// const blob = XLSX.write(wb, { bookType: 'xlsx', mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

// const blob = XLSX.write(wb,"MYSavedData.xlsx" );




      // let csv = "";
      // //define the heading for each row of the data
      // //  Object.keys(data[0]).map((key,i)=>{
      // //     csv=csv+','+key

      // // })
      // csv = "website";

      // // csv=csv.slice(1,(csv.length))+'\n'
      // csv = csv + "\r\n";
      // //  var csv = 'Name,Profession\n';

      // //merge the data with CSV
      // // console.log(main_array,"mainarray");

      // main_array.forEach(function (row) {
      //   csv += row.join(",");
      //   csv += "\r\n";
      // });
      // console.log(csv,"csvvv");
      //display the created CSV data on the web browser
      //  document.write(csv);

      // var hiddenElement = document.createElement("a");
      // // excelData = "data:text/xlsx," + encodeURI(excelData);


      // // hiddenElement.href = "data:text/xlsx," + encodeURI(csv);
      // hiddenElement.href = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet," + encodeURI(MYSavedData);
      // hiddenElement.href = URL.createObjectURL(MYSavedData);
      // // data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
      // hiddenElement.target = "_blank";

      // //provide the name for the CSV file to be downloaded
      // //  hiddenElement.download = new Date().getTime()+'Scrap Data.csv';
      // hiddenElement.download = inputValue.csv_name + ".xlsx";
      // hiddenElement.click();
    }
    csv_file();
  };

  useEffect(() => {
    // console.log(formerror,"errorllll")
    // console.log(Object,"object")
    if (Object.keys(formerror).length === 0 && isSubmit) {
      $("#imageParent").css("display", "block");
      // $("#container_main_id").css("-webkit-filter", "brightness(50%)")

      // const data = { url: search_url };
      // const customConfig = {
      //     headers: {
      //     'Content-Type': 'application/json'
      //     }
      // };
      // const formData = new FormData();
      // formData.append('search_url', encodeURI(search_url))
      // console.log(formData.get('search_url'))
      // formData.append(key, input[key])
      // Object.keys(input).forEach((key) => {
      //     formData.append(key, input[key])
      //   })

      let copyObject = { ...inputValue };
      // console.log(copyObject,"copy object")
      // copyObject.forEach((cpItem)=>{})
      if (!copyObject.phone) {
        {
          copyObject = {
            ...copyObject,
            phone: "",
          };
          // copyObject=inputValue;
          // delete copyObject['phone']
          // Object.assign(copyObject,{phone:''})
        }
      }

      delete copyObject["csv_name"];
if(checkboxStatus=="both-state" ||checkboxStatus=="database-state"){

      Object.assign(copyObject, { urls: selectedOption.filter(opt => opt.label !== 'google-search') });

}
else{
      Object.assign(copyObject, { urls: selectedOption.filter(opt => opt.label == 'google-search')  });
}
      // Object.assign(copyObject, { urls: selectedOption.filter(opt => opt.label !== 'google-search') });


      console.log(copyObject,"copyobjectt")


      // selectedOption.filter(opt => opt.label !== 'google-search')
      // console.log(copyObject,"copyObjecttt")
      // console.log(inputValue,"copyObjecttt123")
      // console.log(copyObject,"copyyy");
      // +Azswedr56
      // const formData = new FormData();

      // Object.keys(copyObject).forEach((key) => {
      //     formData.append(key, copyObject[key])
      //   })
      // http://ec2-54-152-121-69.compute-1.amazonaws.com/api/post
      // http://ec2-54-194-78-171.eu-west-1.compute.amazonaws.com:5000/api
      // http://localhost:5000/api/post
      // http://ec2-54-194-78-171.eu-west-1.compute.amazonaws.com:5000/api/post
      // http://ec2-54-194-78-171.eu-west-1.compute.amazonaws.com:5000/api/post
      // http://ec2-52-16-90-90.eu-west-1.compute.amazonaws.com:5000/api/post
      // http://ec2-52-16-90-90.eu-west-1.compute.amazonaws.com:5000/api/post
// "homepage": "https://ocean.terrogence-global.com",
// https://oceanbackend.terrogence-global.com/api/post
// https://ocean.terrogence-global.com/api/post

      Axios.post(

        ''+API_URL+'/api/post',
        copyObject
      )
        .then((response) => {
          // http://ec2-52-16-90-90.eu-west-1.compute.amazonaws.com:5000/
          //  console.log(response,"responseee")
          $("#imageParent").css("display", "none");
          // $("#container_main_id").css("-webkit-filter", "brightness(100%)")
          const respArr = response.data.result;
          if (response.data.result_code == "200") {
            setResponseData(response.data.result);
            console.log(response, "data");
            download_csv_file(response.data.result);

            // setDataName(response)
          } else if (response.data.result_code == "204") {
            console.log(response, "data");
            // console.log(respArr, "data-resparr")

            if (respArr.length > 0) {
              $(".excludedDBalert").css("display", "block");
              setInfoArr(respArr[0]);
            }

            toast("" + response.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              // progress: undefined,
              theme: "light",
            });
          }
        })
        .catch(function (errorData) {
          $("#imageParent").css("display", "none");
          $("#container_main_id").css("-webkit-filter", "brightness(100%)");

          alert("something error happend!!");

          console.log(errorData);
        });
    }
  }, [formerror]);

  const submitThis = (e) => {
    //  console.log(inputValue)
    e.preventDefault();
    // if(input.password==arr[0].password){
    //     console.log("rammoy")
    // }
    // else{
    //     $('.flexbox .flexBox-inner .wrong').css("display","block");
    //     setChecker(true)
    // }

    // console.log(inputValue,"input")
    //   setInputValue((inputValue)=>{
    // let trimmedObject={}

    //  Object.keys(inputValue).forEach((key)=>{
    //   if(key!=='phone'){
    //  trimmedObject[key]=inputValue[key].trim()
    //   }
    // })
    // console.log(trimmedObject,"kioo")
    // return trimmedObject

    let trimmedObject = {};

    Object.keys(inputValue).forEach((key) => {


      // first_name: "",
      // middle_name: "",
      // last_name: "",
      // email: "",
      // phone: "",
      // csv_name: "",
      // chooseItems: ['database','google'],
  

      // chooseItems
      console.log(key,"keyyy")
      if (key == "first_name" ||key == "middle_name" || key == "last_name" ||key == "email" ||key == "csv_name" ) {
        console.log("innn")
        trimmedObject[key] = inputValue[key].trim();
      }
    //  else if (key !== "chooseItems" ) {
    //     console.log("innn")
    //     trimmedObject[key] = inputValue[key].trim();
    //   }
      // || key !="chooseItems" 
      
      else {
        trimmedObject[key] = inputValue[key];
      }
    });

    setInputValue({ ...trimmedObject });

    //  })
    // setInputValue({...inputValue,first_name:inputValue.first_name.trim(),middle_name:inputValue.middle_name,last_name:inputValue.last_name,email:inputValue.email})

    setFormError(validate(trimmedObject));

    setIsSubmit(true);
  };
  const validate = (values) => {
    const error = {};

    // if()
    // if($("#react-select-3-input").hasClass("is-invalid"))
    // {
    // }
if(checkboxStatus!=="google-state"){
    if (selectedOption.length > 0) {
      selectedOption.forEach((option) => {
        // switch (url.label) {
        //   case "https://www.casecheck.co.uk":
        switch (option.label) {
          case "https://www.familywatchdog.us/ShowNameList.asp":
            if (!values.last_name) {
              error.lastName = "Please provide a Family/Father name";
            }
            // if(!values.first_name){
            //   error.firstName="Please provide a First Name"
            //     }
            break;

          // if(values.first_name){

          //   if($("#first_name_input").hasClass("is-invalid")){
          //     console.log("uinnnn")
          //     $("#first_name_input").removeClass('is-invalid')
          //   }
          //   // $("#first_name_input").addClass('is-invalid')
          //   // error.firstName="this field is required"
          // }
          // else{
          //   // $("#first_name_input").addClass('is-invalid')
          //   error.firstName="this field is required"
          //   if(!$("#first_name_input").hasClass("is-invalid")){
          //     // $("#last_name_input").removeClass('is-invalid')

          //     $("#first_name_input").addClass('is-invalid')
          //   }
          //   // if(!values.first_name){
          //   // }
          // }
          // if(values.last_name){
          //   if($("#last_name_input").hasClass("is-invalid")){
          //     $("#last_name_input").removeClass('is-invalid')
          //   }
          //   // $("#first_name_input").addClass('is-invalid')
          //   // error.firstName="this field is required"
          // }
          // else{
          //   // $("#first_name_input").addClass('is-invalid')
          //   error.last_name="this field is required"
          //   if(!$("#last_name_input").hasClass("is-invalid")){
          //     // $("#last_name_input").removeClass('is-invalid')
          //     $("#last_name_input").addClass('is-invalid')
          //   }

          //   // if(!values.first_name){
          //   // }

          // }

          case "https://www.gov.uk/government/publications/financial-sanctions-consolidated-list-of-targets/consolidated-list-of-targets":
            if (!values.last_name) {
              error.lastName = "Please provide a Family/Father Name";
            }
            break;
          case "https://www.interpol.int/en/How-we-work/Notices/View-Red-Notices":
            if (!values.last_name) {
              error.lastName = "Please provide a Family/Father Name";
            }
            break;
          case "https://www.insolvencydirect.bis.gov.uk/eiir/IIRRegisterNameInput.asp?option=NAME&court=ALL":
            if (!values.last_name) {
              error.lastName = "Please provide a Family/Father Name";
            }
            break;
          case "https://isi.jahs.ie/public/cases":
            if (!values.first_name) {
              error.firstName = "Please provide a First Name";
            }
            if (!values.last_name) {
              error.lastName = "Please provide a Family/Father Name";
            }
            break;
          case "http://ir.drcor.mcit.gov.cy/home/searchperson":
            if (!values.first_name) {
              error.firstName = "Please provide a First Name";
            }
            if (!values.last_name) {
              error.lastName = "Please provide a Family/Father Name";
            }
            break;
          case "https://www.search.gov.hk/result":
            if (!values.last_name) {
              error.lastName = "Please provide a Family/Father Name";
            }
            break;
          case "https://rupep.org/en/search?q=":
            if (!values.last_name) {
              error.lastName = "Please provide a Family/Father Name";
            }
            break;
          case "https://судебныерешения.рф/extended-search":
            if (!values.first_name) {
              error.firstName = "Please provide a First Name";
            }
            break;
          case "https://www.bop.gov/inmateloc/":
            if (!values.first_name) {
              error.firstName = "Please provide a First Name";
            }
            if (!values.last_name) {
              error.lastName = "Please provide a Family/Father Name";
            }
            break;
          case "https://rps.ms.gov.pl/en-US/Public#/home":
            if (!values.last_name) {
              error.lastName = "Please provide a Family/Father Name";
            }
            break;

            case "https://poszukiwani.policja.pl/pos/form/5,dok.html":
              if (!values.last_name) {
                error.lastName = "Please provide a Family/Father Name";
              }
              break;
              case "https://wanted.mvs.gov.ua/searchperson":
                if (!values.first_name) {
                  error.firstName = "Please provide a First Name";
                }
                if (!values.last_name) {
                  error.lastName = "Please provide a Family/Father Name";
                }
                break;
                // case "google-search":
                //   break;

          //  if(values.last_name){
          //         if($("#last_name_input").hasClass("is-invalid")){
          //           $("#last_name_input").removeClass('is-invalid')
          //         }
          //         // $("#first_name_input").addClass('is-invalid')
          //         // error.firstName="this field is required"
          //       }
          //       else{
          //         // $("#first_name_input").addClass('is-invalid')
          //         error.last_name="this field is required"
          //         if(!$("#last_name_input").hasClass("is-invalid")){
          //           // $("#last_name_input").removeClass('is-invalid')
          //           $("#last_name_input").addClass('is-invalid')
          //         }

          //         // if(!values.first_name){
          //         // }

          //       }

          //             if (!values.last_name){

          // error.lastName="this field is required"
          // // $("#first_name_input").addClass('is-invalid')

          //             }
        }
      });
    } else {
      error.data_base = "Please provide at least one database";
    }
  }
  
    if (values.phone) {
      if (!isValidPhoneNumber(values.phone)) {
        error.phone_input = "Please provide a valid phone";
      }
    }
    const regx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    //    const regxPhone = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/

    if (values.email) {
      if (!regx.test(values.email)) {
        error.email_input = "Please provide a valid email";
      }
    }
    if (values.csv_name.length < 1) {
      error.csv_input = "Please provide a output-csv file name";
    }

    // if()
    // if(selectedOption)

    //  if(!values.name){
    //  error.Query="query is required !"
    //  }

    return error;
  };

  // useEffect(()=>{
  //   Axios.get("http://ec2-54-152-121-69.compute-1.amazonaws.com/api").then((response) => {
  //     console.log(response,"ubunt")
  //   });

  // },[])
  const toggleSwitcher = () => {
    if ($(".switchertoggle").find("input.cb-value").is(":checked")) {
      $(".switchertoggle").addClass("switch-dark");
      $("body").addClass("switch-dark");
      setModeState("dark");
    } else {
      $(".switchertoggle").removeClass("switch-dark");
      $("body").removeClass("switch-dark");
      setModeState("light");
    }
  };

  let dark_bg_style = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      // marginTop:'10px',
      //   border: 2px solid #ddd,

// commentout 12-12-23

      // borderTop: "transparent",
      // borderRight: "transparent",
      // borderLeft: "transparent",
      // paddingLeft: "0",
      // borderRadius: "0",
      // borderColor: "#676a6c",
      // marginLeft:"0px",
      
      // commentout 12-12-23
      background: "transparent",




      // paddingLeft:"0px",
      // paddingRight:"0px",
      // padding:"0px"
    }),
    indicatorSeparator: (styles, state) => ({
      ...styles,
      backgroundColor: "#676a6c",
    }),
    multiValue: (styles, state) => ({
      ...styles,
      background: "#303437",
      // color:'#D2DCDE'
    }),
    multiValueLabel: (styles, state) => ({
      ...styles,
      color: "#D2DCDE",
    }),
    multiValueRemove: (styles, state) => ({
      ...styles,
      color: "#D2DCDE",
      ":hover": {
        backgroundColor: "#303437",
        color: "#D2DCDE",
      },
    }),
    menu: (styles, state) => ({
      ...styles,
      border: "#fff",
      //  color:'#000',
      //  background:'#000'
    }),
    menuList: (styles, state) => ({
      ...styles,
      // color:'#000',
      background: "#3D4144",
      border: "#fff",
    }),
    valueContainer:(styles, state)=>({
      ...styles,
      // color:'#000',
    // paddingLeft:"0px",
    // paddingRight:"0px",
    padding:"0px",
    maxHeight: "150px",
    overflow: "auto",
    // webkitScrollbarTrack:"#f1f1f1",
    // webkitScrollbarThumb:"#424242",
    // webkitScrollbar:"25px",
    // &::WebkitScrollbar:""
    // "::-webkit-scrollbar": {
    //   width: "4px",
    //   height: "0px",
    // }



    
    
    
    
    
    
    
    "::-webkit-scrollbar" :{
      width: "12px"
    },
    "::-webkit-scrollbar-track" :{
      background: "#f1f1f1"
    },
    "::-webkit-scrollbar-thumb" :{
      background: "#424242"
    }
    // .css-17dvp3m::-webkit-scrollbar-thumb:hover {
    //   background: #424242;
    // }


    }),
    // menuPortal: (styles, state) => ({
    //   ...styles,
    //   // color:'#000',
    //   // background:'#000',
    //   border:'#fff'
    // }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        // backgroundColor: isDisabled
      //   ? undefined
        //   : isSelected
        //   ? data.color
        //   : isFocused
        //   ? color.alpha(0.1).css()
        //   : undefined,

        color: isDisabled ? "#D2DCDE" : "#D2DCDE",

        cursor: isDisabled ? "not-allowed" : "default",

        backgroundColor: isFocused ? "transparent" : "transparent",
        backgroundColor: isSelected ? "red" : "transparent",
        border: isFocused ? "1px solid #BEBEBE" : "default",
        // background:'transparent'

        // ':active': {
        //   ...styles[':active'],
        //   backgroundColor: "#000"

        //   // backgroundColor: !isDisabled
        //     // ? "#fff"
        //     //   ? data.color
        //     //   : color.alpha(0.3).css()
        //     // : undefined,
        // },
      };
    },
  };
  let light_bg_style = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      // marginTop:'10px',
      //   border: 2px solid #ddd,

// comment out--12-12-23

      // borderTop: "transparent",
      // borderRight: "transparent",
      // borderLeft: "transparent",
      // paddingLeft: "0",
      // background: "transparent",
      // borderRadius: "0",
  // comment out--12-12-23
    }),
    multiValue: (styles, state) => ({
      ...styles,
      background: "default",
      // color:'#D2DCDE'
    }),
    multiValueLabel: (styles, state) => ({
      ...styles,
      color: "default",
    }),
    multiValueRemove: (styles, state) => ({
      ...styles,
      color: "default",
    }),
    // multiValueRemove: (styles, state) => ({
    //   ...styles,
    //   color: '#D2DCDE',
    //   ':hover': {
    //     backgroundColor: '#303437',
    //     color: '#D2DCDE',
    //   }
    // }),
    //   menu: (styles, state) => ({
    //     ...styles,
    // //  color:'#000',
    //  background:'#000'
    //   }),
    menuList: (styles, state) => ({
      ...styles,
      // color:'#000',
      background: "default",
    }),



    valueContainer:(styles, state)=>({
      ...styles,
      // color:'#000',
    // paddingLeft:"0px",
    // paddingRight:"0px",
    padding:"0px",
    maxHeight: "150px",
    overflow: "auto",
    "::-webkit-scrollbar" :{
      width: "12px"
    },
    "::-webkit-scrollbar-track" :{
      background: "#f1f1f1"
    },
    "::-webkit-scrollbar-thumb" :{
      background: "#424242"
    }


    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        // backgroundColor: isDisabled
        //   ? undefined
        //   : isSelected
        //   ? data.color
        //   : isFocused
        //   ? color.alpha(0.1).css()
        //   : undefined,
        color: "default",

        // cursor: isDisabled ? 'not-allowed' : 'default',

        // backgroundColor: 'default'

        // ':active': {
        //   ...styles[':active'],
        //   backgroundColor: "#000"

        //   // backgroundColor: !isDisabled
        //     // ? "#fff"
        //     //   ? data.color
        //     //   : color.alpha(0.3).css()
        //     // : undefined,
        // },
      };
    },
  };
  useEffect(() => {
    if (infoArr.length > 0) {
      setTimeout(() => {
        $(".excludedDBalert").css("display", "none");
      }, 7000);
    }
    // $("")
  }, [infoArr]);

  const changeBt = (e) => {
    // console.log(e.target, "etargettt");
    if (e.target.name == "chooseItems") {
      const { value, checked } = e.target;

      const { chooseItems } = inputValue;

      console.log(`${value} is ${checked}`);

      console.log(e.target.value, "valueee");
      // setChecked(!checked)
      // if(!checked){
      //   setValue(e.target.value)

      // }
      console.log(checked);

      if (checked) {

// if(value=="google")
// {

//   setSelectedOption(()=>{return([...selectedOption,{label:"google-search"}])})

// }


        setInputValue({
          ...inputValue,
          chooseItems: [...chooseItems, value],
        });
      } else {
        // if(value=="google")
        // {
        
        //   setSelectedOption(()=>{let cpySelectedOption=[...selectedOption];
        //    return cpySelectedOption.filter((e)=>e.label!=="google-search")
        //   })
        
        // }


        setInputValue({
          ...inputValue,
          chooseItems: chooseItems.filter((e) => e !== value),
        });
      }
    }
  };

  return (
    <>
    {/* <Main/> */}
      <ToastContainer />
      {/* <Main submitThis={submitThis}/> */}
      {console.log(selectedOption,"selectedd",checkboxStatus)}
      <div
        className="parent-backgrond-img"
        style={ (modeState=="light")?{ backgroundImage: `url(${bgImage})`}: { backgroundImage: `url(${bgImage_db})`}}
      >
        {console.log(inputValue.chooseItems,"chooseitem123555555")}
        <div className="container-need container">
          {/* <div id='imageParent' className="loadingParent">
          <img src={spinner} alt='Loading ....' />
        </div> */}
          <div id="imageParent" className="loadingParent">
            <div class="lds-ripple">
              <div></div>
              <div></div>
            </div>
          </div>
          <div id="container_main_id" className="container-dash">
            <div className="main-input">
              <div className="main-input-logo">
                {modeState == "light" ? (
                  <>
                  
                  
                  <a href="#">
                    <img src={oceanWb} className="oceanLogo" alt="ocean logo" />
                  </a>
                  <a href="#">
                  <img
                    src={oceanGroupLogo}
                    className="oceanGroupLogo"
                    alt="oceanLogo"
                  />
                </a>
                  </>

                  
                ) : (
                  <>
                  
                  <a href="#">
                    <img src={oceanBb} className="oceanLogo" alt="ocean logo" />
                  </a>

                  <a href="#">
                  <img
                    src={oceanGroupLogoDB}
                    className="oceanGroupLogo"
                    alt="oceanLogo"
                  />
                </a>
                  </>
                )}
                {/* <a href="#">
                  <img
                    src={oceanGroupLogo}
                    className="oceanGroupLogo"
                    alt="oceanLogo"
                  />
                </a> */}

                {/* <div className="main-input-logo-photo">
              </div> */}
              </div>
              <form className="new-form form-set" onSubmit={submitThis}>
                {/* <div  className="flexBox-inner relative" >
            <input type="text" id="searchInput" className="fullInput" name="query" value={inputValue.query} onChange={changeInput}  />
           
      
           </div> */}
                <div className="row">
                  <div className="flexBox-inner form-group relative col-12 col-md-6 col-lg-4 col-xl-4">
                    <label htmlFor="first_name_input">First name</label>
                    <input
                      type="text"
                      id="first_name_input"
                      placeholder="First name"
                      className="fullInput"
                      name="first_name"
                      value={inputValue.first_name}
                      onChange={changeInput}
                    />
                    {/* <div class="invalid-feedback">
    First name is required
          
           </div> */}
                    <div className="formerror">
                      {<p>{formerror.firstName} </p>}
                    </div>
                  </div>
                  {/* ---- */}
                  <div className="flexBox-inner relative col-12 col-md-6 col-lg-4 col-xl-4">
                    <label htmlFor="middle_name_input">Second Name </label>
                    <input
                      type="text"
                      id="middle_name_input"
                      placeholder="Second Name"
                      className="fullInput"
                      name="middle_name"
                      value={inputValue.middle_name}
                      onChange={changeInput}
                    />

                    {/* <div className="formerror">
            
       { <p >{formerror.Username } </p>}
        </div>
         */}
                  </div>
                  {/* ---- */}
                  {/* ---- */}
                  <div className="flexBox-inner relative col-12 col-md-6 col-lg-4 col-xl-4">
                    <label htmlFor="last_name_input">Family/Father Name </label>
                    <input
                      type="text"
                      id="last_name_input"
                      placeholder="Family/Father Name"
                      className="fullInput"
                      name="last_name"
                      value={inputValue.last_name}
                      onChange={changeInput}
                    />
                    {/* <div class="invalid-feedback">
    Last name is required
           </div> */}
                    <div className="formerror">
                      {<p>{formerror.lastName} </p>}
                    </div>
                  </div>
                  {/* ---- */}
                  <div className="flexBox-inner relative col-12 col-md-6 col-lg-4 col-xl-4">
                    <label htmlFor="email_input"> Email</label>
                    <input
                      type="text"
                      id="email_input"
                      placeholder="Email"
                      className="fullInput"
                      name="email"
                      value={inputValue.email}
                      onChange={changeInput}
                    />

                    <div className="formerror">
                      {<p>{formerror.email_input} </p>}
                    </div>
                  </div>

                  <div className="flexBox-inner  relative col-12 col-md-6 col-lg-4 col-xl-4">
                    <label htmlFor="phone"> Phone</label>
                    <PhoneInput
                      name="phone"
                      class="phone-idea"
                      placeholder="Phone"
                      value={inputValue.phone}
                      onChange={changePhone}
                    />

                    <div className="formerror">
                      {<p>{formerror.phone_input} </p>}
                    </div>
                  </div>
                  <div className="flexBox-inner flex-common relative col-12 col-md-6 col-lg-4 col-xl-4">
                    <label htmlFor="csv_name_input">
                      CSV Name (Ex: John-Doe){" "}
                    </label>
                    <input
                      type="text"
                      id="csv_name_input"
                      placeholder="CSV Name (Ex:John-Doe)"
                      className="fullInput"
                      name="csv_name"
                      value={inputValue.csv_name}
                      onChange={changeInput}
                    />
                    {/* <div class="invalid-feedback">
    Last name is required
           </div> */}
                    <div className="formerror">
                      {<p>{formerror.csv_input} </p>}
                    </div>
                  </div>
                </div>

                <div className="row query-status">
                  <div className="flexBox-inner flex-common  relative col-12 col-md-6 col-lg-4">
                    <div className="custom-p-custom-checkbox">

                   
        
                    <div className="custom-checkbox">
                      <input
                        type="checkbox"
                        name="chooseItems"
                        id="database"
                        checked={inputValue.chooseItems.find(
                          (arr) => arr == "database"
                        )}
                        value="database"
                        onChange={changeBt}
                      />

                      <label htmlFor="database">
                        Database
                      </label>
                    </div>
                    <div className="custom-checkbox">
                      <input
                        type="checkbox"
                        name="chooseItems"
                        id="google"
                        checked={inputValue.chooseItems.find(
                          (arr) => arr == "google"
                        )}
                        value="google"
                        onChange={changeBt}
                      />

                      <label htmlFor="google">
                        Google
                      </label>
                    </div>
                    </div>
                  </div>
                </div>

                {/* <div  className="flexBox-inner relative" >
           <label htmlFor="">Multiple query</label>
            <input type="textarea" id="multiple_input" className="fullInput" name="multiple_query" value={inputValue.multiple_query} onChange={changeInput}  />
           
    
           </div> */}
                <div className="row">
                  <div className="flexBox-inner flex-common relative col-12 col-md-12 col-lg-12 col-xl-12">
                    <label htmlFor="">
                      Database{" "}
                      <span className="spanMandotory">
                        {" "}
                        Required:{" "}
                        {list.map((item, item_index) => {
                          return (
                            <span>
                              {item}
                              {item_index != list.length - 1 && (
                                <span className="commaClass">, </span>
                              )}
                            </span>
                          );
                        })}
                      </span>
                    </label>
                    {

                    // !((inputValue.chooseItems.length==1)&&(inputValue.chooseItems.includes("google")))&&
                     (checkboxStatus)&&(!(checkboxStatus=="google-state")) &&

                    <Select
                      // value={()=>{console.log("innn",selectedOption); return(selectedOption.filter(opt=>opt.label=="google-search"))}}
                      value={selectedOption.filter(opt => opt.label !== 'google-search')}
                      // value={selectedOption}

                      placeholder="Search"
                      styles={
                        modeState == "light" ? light_bg_style : dark_bg_style
                      }

                      // isSearchable={false}
                      // isDisabled={((inputValue.chooseItems.length==1)&&(inputValue.chooseItems.includes("google")))?true:false }
                      //                   styles={{
                      //                     control: (baseStyles, state) => ({
                      //                       ...baseStyles,
                      //                       // marginTop:'10px',
                      //                       //   border: 2px solid #ddd,
                      //                       borderTop: 'transparent',
                      //                       borderRight: 'transparent',
                      //                       borderLeft: 'transparent',
                      //                       paddingLeft: '0',
                      //                       background:'transparent'
                      //                     }),
                      //                     multiValue:(styles, state)=>({
                      //                       ...styles,
                      // background: '#303437'
                      // // color:'#D2DCDE'
                      // ,

                      //                     }),
                      //                     multiValueLabel: (styles, state) => ({
                      //                       ...styles,
                      //                       color: '#fff',
                      //                     }),
                      //                     multiValueRemove: (styles, state) => ({
                      //                       ...styles,
                      //                    color:'#fff'
                      //                     }),
                      //                   //   menu: (styles, state) => ({
                      //                   //     ...styles,
                      //                   // //  color:'#000',
                      //                   //  background:'#000'
                      //                   //   }),
                      //                     menuList: (styles, state) => ({
                      //                       ...styles,
                      //                       // color:'#000',
                      //                       background:'#000'
                      //                     }),
                      //                     option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                      //                       // const color = chroma(data.color);
                      //                       return {
                      //                         ...styles,
                      //                         // backgroundColor: isDisabled
                      //                         //   ? undefined
                      //                         //   : isSelected
                      //                         //   ? data.color
                      //                         //   : isFocused
                      //                         //   ? color.alpha(0.1).css()
                      //                         //   : undefined,
                      //                         color: isDisabled
                      //                           ? '#fff'
                      //                           : '#fff',

                      //                         cursor: isDisabled ? 'not-allowed' : 'default',

                      //                         backgroundColor: isFocused ? "red" :'deafault'

                      //                         // ':active': {
                      //                         //   ...styles[':active'],
                      //                         //   backgroundColor: "#000"

                      //                         //   // backgroundColor: !isDisabled
                      //                         //     // ? "#fff"
                      //                         //     //   ? data.color
                      //                         //     //   : color.alpha(0.3).css()
                      //                         //     // : undefined,
                      //                         // },
                      //                       };
                      //                     },

                      //                   }}

                      isMulti
                      // isOptionDisabled={(option) => console.log(option,"optionnn")}
                      // defaultValue={selectedOption}
                      // filterOption={(option)=>{console.log(option,"optionnn")}}
                      onChange={handleSelect}
                      options={options}
                      // filterOption={(selectedOption.find(obj=>obj.label=="google-search"))?true:false}
                      // filterOption={(option) =>{console.log("inn opno");return( option.label == "google-search")}}
                    />
}
                    <div className="formerror">
                      {<p>{formerror.data_base} </p>}
                    </div>
                  </div>
                  {/* <button className="absolute-searchIcon search-config" id="searchBtn" type='submit'>
                Search
            </button> */}

                  {infoArr.length > 0 && (
                    <div className="col-12 excludedDBalert">
                      <div class="alert alert-info" role="alert">
                        <h5>Database Error(Ex: Server down):</h5>
                        <ul>
                          {infoArr.map((item) => {
                            return <li>{item}</li>;
                          })}
                        </ul>

                        {/* <li>
                https://www.police.hu/kereses?q=
                </li> */}
                        {/* <li>
                https://www.insolvencydirect.bis.gov.uk/eiir/IIRRegisterNameInput.asp?option=NAME&court=ALL
                </li>
                <li>
                https://www.police.hu/kereses?q=
                </li> */}

                        {/* infoArr.map((arr)=>) */}
                      </div>
                    </div>
                  )}
                  <div className="col-12">
                  {/* ((inputValue.length>1)||(inputValue.chooseItems.length==1)&&(inputValue.chooseItems.includes("database")) ) */}
                    {(inputValue.first_name ||
                      inputValue.middle_name ||
                      inputValue.last_name ||
                      inputValue.email ||
                      inputValue.phone) && 
                      // inputValue.chooseItems.length>0 &&
                      checkboxStatus &&

                 ( selectedOption.length > 0) ? (
                      <button
                        className="absolute-searchIcon search-config"
                        id="searchBtn"
                        type="submit"
                      >
                        Search
                      </button>
                    ) : (
                      <button
                        className="absolute-searchIcon search-config disabled-btn"
                        disabled
                        id="searchBtn"
                        type="btn"
                      >
                        Search
                      </button>
                    )}
                  </div>
                </div>
              </form>

              <div className="database-requred">

              Version 2.1.1
                
                
                 </div>
            </div>
          </div>

          <div class="switchertoggle">
            <div class="darktoggle-btn">
              <input
                type="checkbox"
                class="cb-value"
                onClick={toggleSwitcher}
              />
              <span class="round-btn">
                <svg
                  width="20"
                  height="22"
                  viewBox="0 0 20 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 18.7978C7.91667 18.7978 6.14583 18.0351 4.6875 16.5096C3.22917 14.9841 2.5 13.1317 2.5 10.9524C2.5 8.77316 3.22917 6.92078 4.6875 5.39529C6.14583 3.8698 7.91667 3.10706 10 3.10706C10.1944 3.10706 10.3856 3.11432 10.5733 3.12885C10.7611 3.14338 10.945 3.16517 11.125 3.19423C10.5556 3.61555 10.1006 4.16415 9.76 4.84001C9.41944 5.51587 9.24944 6.24578 9.25 7.02974C9.25 8.3373 9.6875 9.44873 10.5625 10.364C11.4375 11.2793 12.5 11.737 13.75 11.737C14.5139 11.737 15.2153 11.5588 15.8542 11.2026C16.4931 10.8464 17.0139 10.3707 17.4167 9.77562C17.4444 9.96449 17.4653 10.1568 17.4792 10.3527C17.4931 10.5485 17.5 10.7484 17.5 10.9524C17.5 13.1317 16.7708 14.9841 15.3125 16.5096C13.8542 18.0351 12.0833 18.7978 10 18.7978Z"
                    fill="white"
                  ></path>
                </svg>

                <svg
                  enableBackground="new 0 0 512 512"
                  height="512px"
                  id="Layer_1"
                  version="1.1"
                  viewBox="0 0 512 512"
                  width="512px"
                  xmlSpace="preserve"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                  <g>
                    <g>
                      <path
                        d="M256,144c-61.75,0-112,50.25-112,112s50.25,112,112,112s112-50.25,112-112S317.75,144,256,144z M256,336    c-44.188,0-80-35.812-80-80c0-44.188,35.812-80,80-80c44.188,0,80,35.812,80,80C336,300.188,300.188,336,256,336z M256,112    c8.833,0,16-7.167,16-16V64c0-8.833-7.167-16-16-16s-16,7.167-16,16v32C240,104.833,247.167,112,256,112z M256,400    c-8.833,0-16,7.167-16,16v32c0,8.833,7.167,16,16,16s16-7.167,16-16v-32C272,407.167,264.833,400,256,400z M380.438,154.167    l22.625-22.625c6.25-6.25,6.25-16.375,0-22.625s-16.375-6.25-22.625,0l-22.625,22.625c-6.25,6.25-6.25,16.375,0,22.625    S374.188,160.417,380.438,154.167z M131.562,357.834l-22.625,22.625c-6.25,6.249-6.25,16.374,0,22.624s16.375,6.25,22.625,0    l22.625-22.624c6.25-6.271,6.25-16.376,0-22.625C147.938,351.583,137.812,351.562,131.562,357.834z M112,256    c0-8.833-7.167-16-16-16H64c-8.833,0-16,7.167-16,16s7.167,16,16,16h32C104.833,272,112,264.833,112,256z M448,240h-32    c-8.833,0-16,7.167-16,16s7.167,16,16,16h32c8.833,0,16-7.167,16-16S456.833,240,448,240z M131.541,154.167    c6.251,6.25,16.376,6.25,22.625,0c6.251-6.25,6.251-16.375,0-22.625l-22.625-22.625c-6.25-6.25-16.374-6.25-22.625,0    c-6.25,6.25-6.25,16.375,0,22.625L131.541,154.167z M380.459,357.812c-6.271-6.25-16.376-6.25-22.625,0    c-6.251,6.25-6.271,16.375,0,22.625l22.625,22.625c6.249,6.25,16.374,6.25,22.624,0s6.25-16.375,0-22.625L380.459,357.812z"
                        fill="#1D1D1B"
                      ></path>
                    </g>
                  </g>
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Scrap;

