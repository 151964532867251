
import './App.css';
import Scrap from './pages/Scrap'
import './assets/oxy.css'


function App() {
  return (
    <>
    
<Scrap/>

    </>
  );
}

export default App;
