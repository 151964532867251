export const options = [
  // { value: 'All', label: 'All', isDisabled: true},
  // { value: 'All', label: 'All'},
  // { value: 'https://sanctions.acf.international/en', label: 'https://sanctions.acf.international/en'},
  // { value: 'https://www.gov.uk/government/publications/financial-sanctions-consolidated-list-of-target s/c onsolidated-list-of-targets', label: 'https://www.gov.uk/government/publications/financial-sanctions-consolidated-list-of-target s/c onsolidated-list-of-targets'},
  // // { value: 'https://roi.aib.gov.uk/roi/PublicSearches/PublicSearch', label: '. https://roi.aib.gov.uk/roi/PublicSearches/PublicSearch'},
  // { value: 'https://www.online.economy-ni.gov.uk/IVA_Register/IVASearch.aspx', label: 'https://www.online.economy-ni.gov.uk/IVA_Register/IVASearch.aspx'},
  // { value: 'https://www.nsopw.gov/', label: 'https://www.nsopw.gov/'},
  // { value: 'https://www.offenderradar.com', label: 'https://www.offenderradar.com'},
  // { value: 'https://www.familywatchdog.us/ShowNameList.asp', label: 'https://www.familywatchdog.us/ShowNameList.asp'},
  // { value: 'https://theukdb.co.uk/', label: 'https://theukdb.co.uk/'},\
  { value: 'https://www.casecheck.co.uk/search.html?limit=1000&q=', label: 'https://www.casecheck.co.uk'},
  { value: 'https://scamsearch.io/search_report?_token=KvKNbPkLYxcB5HALQyjHquGZDYSdIiK7rC7PgkKj&searchoption=all&search=', label: 'https://scamsearch.io'},
  
  { value: 'https://www.police.be/5998/en/wanted/most-wanted?search_query=', label: 'https://www.police.be/5998/en/wanted'},
    // { value: 'https://www.policija.si/eng/wanted-persons?view=tiraliceseznam', label: 'https://www.policija.si/eng/wanted-persons?view=tiraliceseznam' },
    
    // { value: 'https://www.dnrec.jus.gov.ar/masbuscados#', label: 'https://www.dnrec.jus.gov.ar/masbuscados#' },

    // { value: 'https://www.boloprogram.org/', label: 'https://www.boloprogram.org' },
    { value: 'https://www.opensanctions.org/search/?q=', label: 'https://www.opensanctions.org/search' },
        { value: 'https://www.familywatchdog.us/ShowNameList.asp', label: 'https://www.familywatchdog.us/ShowNameList.asp' },
        // ommited 27-02-24
        { value: 'https://www.gov.uk/government/publications/financial-sanctions-consolidated-list-of-targets/consolidated-list-of-targets', label: 'https://www.gov.uk/government/publications/financial-sanctions-consolidated-list-of-targets/consolidated-list-of-targets' },
    // { value: 'https://www.icac.org.hk/en/law/wanted/index.html', label: 'https://www.icac.org.hk/en/law/wanted/index.html' }
    { value: 'https://www.interpol.int/en/How-we-work/Notices/View-Red-Notices', label: 'https://www.interpol.int/en/How-we-work/Notices/View-Red-Notices' },
    {value:'https://www.fbi.gov/wanted/terrorism',label:'https://www.fbi.gov/wanted/terrorism'},
    {value:'https://rewardsforjustice.net/index/',label:'https://rewardsforjustice.net/index'},
    {value:'https://www.insolvencydirect.bis.gov.uk/eiir/IIRRegisterNameInput.asp?option=NAME&court=ALL',label:'https://www.insolvencydirect.bis.gov.uk/eiir/IIRRegisterNameInput.asp?option=NAME&court=ALL'},
    // {value:'https://www.judiciary.uk/judgments',label:'https://www.judiciary.uk/judgments'},
    {value:'https://offenders.org.uk/?search=',label:'https://offenders.org.uk'},
    {value:'https://www.politie.nl/en/search?type=gezocht%2Cvermist&query=',label:'https://www.politie.nl/en/wanted-and-missing'},
    {value:'https://isi.jahs.ie/public/cases',label:'https://isi.jahs.ie/public/cases'},
    // {value:'http://ir.drcor.mcit.gov.cy/home/searchperson',label:'http://ir.drcor.mcit.gov.cy/home/searchperson'},
    // ----working-but security task
    // {value:'https://www.politiaromana.ro/ro/persoane-urmarite?nume=',label:'https://www.politiaromana.ro/ro/persoane-urmarite'},
    // {value:'https://www.border.gov.md/rezultate-cautare?search_api_fulltext=',label:'https://www.border.gov.md/rezultate-cautare?search_api_fulltext='},
    // {value:'https://www.policija.si/eng/search?searchword=',label:'https://www.policija.si/eng/wanted-persons?view=tiraliceseznam'},
    {value:'https://www.search.gov.hk/result?query=',label:'https://www.search.gov.hk/result'},


    {value:'https://rupep.org/en/search?q=',label:'https://rupep.org/en/search?q='},
    {value:'https://судебныерешения.рф/extended-search',label:'https://судебныерешения.рф/extended-search'},
    // {value:'https://www.nia.gov.in/advanced-search.htm',label:'https://www.nia.gov.in/advanced-search.htm'},
    {value:'https://www.bop.gov/inmateloc/',label:'https://www.bop.gov/inmateloc/'},
    {value:'https://www.offenderradar.com/profilesearch/',label:'https://www.offenderradar.com/'},
    // -----
   //org // {value:'https://www.fbi.gov/wanted/terrorism',label:'https://www.fbi.gov/wanted/terrorism'},

    {value:'https://eumostwanted.eu/search/node?keys=',label:'https://eumostwanted.eu'},

    {value:'https://www.fbi.gov/wanted/fugitives',label:'https://www.fbi.gov/wanted/fugitives'},
    {value:'https://www.judiciary.uk/judgments',label:'https://www.judiciary.uk/judgments'},
    

    {value:'https://www.nationalcrimeagency.gov.uk/most-wanted-search?searchword=',label:'https://www.nationalcrimeagency.gov.uk/most-wanted-search'},
    {value:'https://www.politiaromana.ro/ro/persoane-urmarite?nume=',label:'https://www.politiaromana.ro/ro/persoane-urmarite'},
    {value:'https://rps.ms.gov.pl/en-US/Public#/home',label:'https://rps.ms.gov.pl/en-US/Public#/home'},
    {value:'https://e-justice.europa.eu/advancedSearchManagement?action=advancedSearch',label:'https://e-justice.europa.eu/advancedSearchManagement?action=advancedSearch'},
    
    {value:'https://poszukiwani.policja.pl/pos/form/5,dok.html',label:'https://poszukiwani.policja.pl/pos/form/5,dok.html'},
    
    {value:'https://www.police.hu/kereses?q=',label:'https://www.police.hu/kereses?q='},
    {value:'https://myrotvorets.center/criminal/?cf%5Bname%5D=name&cf%5Bcountry%5D=&cf%5Baddress%5D=&cf%5Bphone%5D=&cf%5Bdesc%5D=',label:'https://myrotvorets.center/criminal/?cf%5Bname%5D=name&cf%5Bcountry%5D=&cf%5Baddress%5D=&cf%5Bphone%5D=&cf%5Bdesc%5D='},
    // https://pnp.gov.ph/communist-terrorist-group
    // {value:'https://pro3.pnp.gov.ph/most-wanted',label:'https://pro3.pnp.gov.ph/most-wanted'},

    // {value:'https://pnp.gov.ph/communist-terrorist-group',label:'https://pnp.gov.ph/communist-terrorist-group'},
    {value:'https://sanctions.acf.international/en',label:'https://sanctions.acf.international/en'},

    {value:'https://wanted.mvs.gov.ua/searchperson',label:'https://wanted.mvs.gov.ua/searchperson'},
    {value:'https://www.border.gov.md/rezultate-cautare?search_api_fulltext=',label:'https://www.border.gov.md/en/rezultate-cautare'},


    // {value:'https://www.nsopw.gov',label:'https://www.nsopw.gov'},
    // {value:'https://www.police.hu/kereses?q=',label:'https://www.police.hu/kereses'},
    
    // https://www.nsopw.gov/

    // https://www.nia.gov.in/advanced-search.htm
    // 29,30,31,32,33,34,37 Shahid Reza
    // {value:'https://sanctions.acf.international/en',label:'https://sanctions.acf.international/en'},
    // 'https%3A%2F%2Frps.ms.gov.pl%2Fen-US%2FPublic%23%2Fhome '
    
    // https://sanctions.acf.international/en
    // https://rps.ms.gov.pl/en-US/Public#/home
    // https://e-justice.europa.eu/advancedSearchManagement?action=advancedSearch
    // https://www.nationalcrimeagency.gov.uk/most-wanted-search






    

    // {value:'https://www.search.gov.hk/result',label:'https://www.search.gov.hk/result'},
    // {value:'https://www.search.gov.hk/result',label:'https://poszukiwani.policja.pl/pos/form/5,dok.html'},
    
    // https://rps.ms.gov.pl/en-US/Public#/home
    // https://poszukiwani.policja.pl/pos/form/5,dok.html
    // https://rupep.org/en/search?q=

    // https://www.policija.si/eng/wanted-persons?view=tiraliceseznam
    
    // https://www.border.gov.md/rezultate-cautare?search_api_fulltext=
    // https://www.politiaromana.ro/ro/persoane-urmarite
    // https://isi.jahs.ie/public/cases



  

  ];
//   export default options